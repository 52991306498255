
.gameOfTheWeek{
	margin: auto; /* horizontally center the element within its parent element */
	text-align: center;
}
.gameOfTheWeek td{
	border-bottom-style: dotted;
	border-bottom-width: thin;
	border-bottom-color: #cccccc;
	width: 33%;
}

.gameOfTheWeek td img{
	height: 30px;
}
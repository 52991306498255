li {
	margin-bottom: 1em;
}

li li {
	margin-bottom: 0;
}

del {
	color:red;
}

ins {
	color:green;
}

.RuleName {
	text-decoration: underline;
	font-weight: bold;
}

.ImportantNote {
	color: #333399;
	font-weight: bold;
}

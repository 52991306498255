.DivClearBoth { clear: both; }

.MunozCandidates {
	border-left: none;
	width: 250px;
}

.MunozCandidatesHeader {
	font-family: arial, helvetica, sans-serif;
	padding-top: 0.1em;
	padding-bottom: 0.2em;
}

.MunozCandidatesDivider { 
	clear: left; 
	background-color: #f8f8f8;
	border-top-style: dotted;
	border-top-color: #cccccc;
	border-top-width: thin;
	border-bottom-style: dotted;
	border-bottom-color: #cccccc;
	border-bottom-width: thin;
	font-family: arial, helvetica, sans-serif;
	padding-top: 0.1em;
	padding-bottom: 0.2em;
}

.MunozCandidate{
}

.MunozCandidateLeft { 
	float: left; 
	margin-bottom: .75em;
	padding-top: .3em
}

.MunozCandidateLeft a img{ 
	height: 40px;
	margin-left: 1.0em;
	margin-top: 0.5em;
}

.MunozCandidateRight { 
	float: left; 
	font-family: arial, helvetica, sans-serif;
	font-variant: none;
	margin-bottom: .3em;
	padding-left: 0.4em;
	padding-top: 0.5em;
	width: 189px;
}
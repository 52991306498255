input { 
	clear:left;
	float:left;
}

label { 
	float:left;
	width: 1em;
	text-align:right;
}

.SeasonCheckboxes {
	float:left;
	width:20%;
}

.Stats {	
	text-align:center;
}

.StatHeader {
	text-align:center;
}
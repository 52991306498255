/*body {	
	background-image: url('bg.gif');
	font-family: arial, sans-serif, verdana;
	width: 99%;
}*/

.boxScore a {
	/* display: block; */
	text-decoration: none;
}

.boxScore a:link{ color: #000000; }
.boxScore a:visited{ color: #000000; }
.boxScore a:hover{ 
	background-color: inherit;
	color: #ffffff; 
}

.boxScore .videoLink{ display: inline; }
.boxScore .videoLink:hover {
	color:inherit;
	text-decoration: underline;
}

.boxScore table{
	border: none;
	border-collapse: collapse;
	border-spacing: 0px;
	margin: 0px;
	padding: 0px;
}

.boxScore .playerStats {
	background-color: #ffffff;
	overflow: hidden;	
}

.boxScore .homePlayerStats table { width: 100%; }
.boxScore .awayPlayerStats table { width: 100%; }

.boxScore .teamStats td {
	font-family: arial, sans-serif, verdana;
	text-align: center;
	width: 380px;
}

.boxScore .oddRow{
	background-color: #e4e9ee;
}

/*
.content {
	background-color: #cccccc;
	margin: auto;
	width: 900px;
}
*/

.boxScore .winnerBanner{	
	/*height: 125px;*/
	text-align: center;
}


.boxScore .home {
	background-color: #ffffff;
	float: left;
}

.boxScore .home a img { border: none; }

/*.boxScoreTeam {
}*/

.boxScore .boxScoreTeam a:hover img { 
	/*filter:alpha(opacity=50);*/
	opacity: 0.5; 
}

.boxScore .boxScoreOwner{
	height: 50px;
	font-family: arial, sans-serif, verdana;
	font-size: 0.9em;
	font-weight: bold;
	text-align: center;
	line-height: 50px;
	width: 180px;
}

.boxScore .boxScoreOwner a { 
	display: block; 
	text-decoration: none;
}

.boxScore .boxScoreOwner a:hover { 
	color: #000000;
	opacity: 0.5;
}

.boxScore .away{
	background-color: #ffffff;
	float: right;
}

.boxScore .away a img { border: none; }

.boxScore .teamStats{
	background-color: #ffffff;
	float: left;
	height: 230px;
	width: 590px;
}

.boxScore .homeScore {		
	color: #000000;
	float: left;
	font-family: arial, sans-serif, verdana;
	font-size: 3em;
	height: 80px;
	line-height: 80px;
	width: 80px;
	text-align: center;
}

.boxScore .awayScore {
	color: #000000;
	float: right;
	font-family: arial, sans-serif, verdana;
	font-size: 3em;
	height: 80px;
	line-height: 80px;
	width: 80px;
	text-align: center;
}

.boxScore .homePlayerStats{	
	clear: both;	
	float: left;	
	width: 49%;
}

.boxScore .homePlayerStats table {
	font-size: 0.9em;
	margin-bottom: 2em;
}

.boxScore .homePlayerStats table th {
	background-color: #ffffff;
	color: #000000;
	text-align: right;
}

.boxScore .homePlayerStats td {
	text-align: right;
	width: 3.5em;
}

.boxScore .homePlayerStats tr:hover {
	color: #ffffff;
	background-color: #39608a;
}

.boxScore .awayPlayerStats{	
	/*To put it nicely...This 'clear: right;' is only here because IE is unable to handle (properly) containers that 
	have both a 'float' and 'clear' property. See http://www.brunildo.org/test/IEWfc3.html 
	Basically, the page should be able to render properly without this 'clear: right;' The .homePlayerStats definition
	should handle pushing the .awayPlayerStats down below the previous .awayPlayerStats div*/
	clear: right; 
	float: right;
	width: 49%;
}

.boxScore .awayPlayerStats table {
	font-size: 0.9em;
	margin-bottom: 2em;
}

.boxScore .awayPlayerStats table th {
	background-color: #ffffff;
	color: #000000;
	text-align: right;
}

.boxScore .awayPlayerStats td {
	text-align: right;
	width: 3.5em;
}

.boxScore .awayPlayerStats tr:hover {
	color: #ffffff;
	background-color: #39608a;
}

.boxScore .homePlayerStats .nameCell, 
.boxScore .awayPlayerStats .nameCell{
	text-align: left;
	width: auto;
}

.boxScore .HighlightVideo {
	background-color: black;
	clear: both;
	text-align: center;		
}

.RecentGames{
	width: 250px;
	text-align: center;
}
.RecentGames td{
	border-bottom-style: dotted;
	border-bottom-width: thin;
	border-bottom-color: #cccccc;
	width: 50%;
}
@charset "ISO-8859-1";

.ErrorBox {
    width: 95%;
    margin-left: auto;
}

.ErrorBox img {
    border: 10px solid silver;
    float: right;
    margin-right: 7em;
}

.ErrorBox ul {
    margin-left: 9em;
    margin-top: 1em;
}

.ErrorBox p {
    margin-left: 7em;
    width: 40%;
}

.ErrorBox li {
	list-style-type: disc;
	list-style-position: inside;
}
.award{
	clear: both;
	margin: auto;
	padding-top: 1.5em;
	width: 600px;
}

.awardImage {
	float: right;
	height: 250px;
	width: 200px
}

.awardDetails {
	float: right;
	width: 400px;
}

.awardResults {
	float: right;
	width: 400px;
}

.awardImageAlt {
	float: left;
	height: 250px;
	width: 200px
}

.awardDetailsAlt {
	float: left;
	width: 400px;
}

.awardResultsAlt {
	float: left;
	width: 400px;
}


.awardName {
	font-size: large;
}

.awardDescription {
	font-style: italic;
	margin-bottom: 1em;
}

.awardWinners {
	width: 100%;
}

.awardWinners td {
	text-align: center;
}

.TopUnderClassmen{
	border-style: solid;
	border-width: thick;
	border-color: #cccccc;
	width: 700px;
}

.TopUnderClassmanPlayer{
	border-bottom-style: dashed;
	border-bottom-width: thin;
	clear: both;
}

.TopUnderClassmanPlayerPartFace{
	float: left;
	text-align: center;
	width: 80px;
}
.TopUnderClassmanPlayerPartFace a img{
	height: 40px;    
}

.TopUnderClassmanPlayerPartName{
	border-left-style: dotted;
	border-left-width: thin;
	border-left-color: #dddddd;
	float: left;
	height: 40px;
	line-height: 40px; /*Make sure this is the same has 'height'. It is being used to vertically center one line of text...*/
	text-align: center;
	width: 200px;
}

.TopUnderClassmanPlayerPartPosition{
	border-left-style: dotted;
	border-left-width: thin;
	border-left-color: #dddddd;
	float: left;
	height: 40px;
	line-height: 40px; /*Make sure this is the same has 'height'. It is being used to vertically center one line of text...*/
	text-align: center;
	width: 2em;
}

.TopUnderClassmanPlayerPartTeam{
	border-left-style: dotted;
	border-left-width: thin;
	border-left-color: #dddddd;
	float: left;
	height: 40px;
	line-height: 40px; /*Make sure this is the same has 'height'. It is being used to vertically center one line of text...*/
	text-align: center;
	width: 3em;
}

.TopUnderClassmanPlayerPartStats{
	border-left-style: dotted;
	border-left-width: thin;
	border-left-color: #dddddd;
	float: left;	
	height: 40px;
	line-height: 40px; /*Make sure this is the same has 'height'. It is being used to vertically center one line of text...*/
	text-align: center;
}